import Moment from "react-moment";
import { useAuth } from "../../../context/auth";
import MinyanSignup from "./MinyanSignUp";
import { useMinyan } from "../../../context/MinyanContext";
import Hebcal from "hebcal";
import { useState } from "react";
import LaterSignup from "./LaterSignup";
import MinyanStatus from "./MinyanStatus";

const MinyanForm = () => {
    var { user, colonyData } = useAuth();
    const { MinyanRecords } = useMinyan();

    const [LaterSignupOpen, setLaterSignupOpen] = useState(false);

    var parshe = new Hebcal.HDate(new Date()).getSedra("h")[0];
    var fullParsha = `שב"ק פר' ${parshe}`;

    if (!MinyanRecords) return null;

    var zmanInfo = {
        date: new Date(user.upcomingShabbos.date),
        latitude: Number(user.colonyData.lat),
        longitude: Number(user.colonyData.lon),
    };
    var sureCount = MinyanRecords.upcomingShabbos
        .filter((mr) => !mr.only_if_minyan)
        .reduce((prev, current) => prev + current.poeple_count, 0);
    var onlyIfCount = MinyanRecords.upcomingShabbos
        .filter((mr) => mr.only_if_minyan)
        .reduce((prev, current) => prev + current.poeple_count, 0);
    var hasMikvah = (colonyData?.hasMikvah === undefined || colonyData?.hasMikvah);
    colonyData.shulOpenAt = colonyData.shulOpenAt || [];
    colonyData.mikvahOpenAt = colonyData.mikvahOpenAt || [];
    var shulOpen = colonyData.shulOpenAt.map((s) => new Date(s).getUTCDate() + "-" + new Date(s).getUTCMonth() + "-" + new Date(s).getUTCFullYear()).includes(new Date(user.upcomingShabbos.date).getUTCDate() + "-" + new Date(user.upcomingShabbos.date).getUTCMonth() + "-" + new Date(user.upcomingShabbos.date).getUTCFullYear());
    var mikvahOpen = colonyData.mikvahOpenAt.map((s) => new Date(s).getUTCDate() + "-" + new Date(s).getUTCMonth() + "-" + new Date(s).getUTCFullYear()).includes(new Date(user.upcomingShabbos.date).getUTCDate() + "-" + new Date(user.upcomingShabbos.date).getUTCMonth() + "-" + new Date(user.upcomingShabbos.date).getUTCFullYear());
    var mussafReservedBy = MinyanRecords.upcomingShabbos.find(record => record.reservedMussaf)?.user_name;
    var baalKorahInfo = {
        '1-4': MinyanRecords.upcomingShabbos.find(record => record.baalKorah && ['1-4', 'full'].includes(record.preparedFor))?.user_name,
        '5-end': MinyanRecords.upcomingShabbos.find(record => record.baalKorah && ['5-end', 'full'].includes(record.preparedFor))?.user_name,
    }
    return (
        <div key={user.upcomingShabbos.date}>
            <div className="max-w-7xl mx-auto px-4 py-5 md:py-16 text-left">
                <div className="grid grid-cols-12 md:gap-11 gap-8">
                    <div className="md:col-span-8 col-span-12">
                        <div className="border-dependable border bg-gray-100 min-h-full  ">
                            <div className="text-center text-white bg-dependable p-4 md:p-6 text-xl md:text-3xl font-semibold uppercase">
                                Add yourself to minyan
                            </div>
                            <div className="">
                                <div className="p-5 md:p-6 md:pl-16 ">
                                    <MinyanSignup eventData={user.upcomingShabbos} date={user.upcomingShabbos.date} />
                                </div>
                                { }
                                <div className="pb-5 pl-5 md:pb-6 md:pl-16 md:-mt-3">
                                    <button
                                        className="text-lg md:text-2 border-4 border-dependable-green px-6 py-4"
                                        onClick={() => setLaterSignupOpen(true)}
                                    >
                                        Sign up for later Shabbos
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <MinyanStatus date={user.upcomingShabbos.date} eventName={fullParsha} isHaliday={false} sureCount={sureCount} onlyIfCount={onlyIfCount} zmanInfo={zmanInfo} hasMikvah={hasMikvah} shulOpen={shulOpen} mikvahOpen={mikvahOpen} mussafReservedBy={mussafReservedBy} baalKorahInfo={baalKorahInfo} />
                </div>
            </div>
            {!!user.upcomingHolidays.length && (
                <>
                    {user.upcomingHolidays.map((upcomingHoliday) => {
                        var minyanRecord = MinyanRecords.upcomingHolidays.filter((mr) => mr.date_title === upcomingHoliday.displayName)[0] || null;
                        sureCount = (minyanRecord) ? Object.values(minyanRecord)[1]
                            .filter((mr) => !mr.only_if_minyan)
                            .reduce((prev, current) => prev + current.poeple_count, 0) : 0;
                        onlyIfCount = (minyanRecord) ? Object.values(minyanRecord)[1]
                            .filter((mr) => mr.only_if_minyan)
                            .reduce((prev, current) => prev + current.poeple_count, 0) : 0;
                        shulOpen = colonyData.shulOpenAt.map((s) => new Date(s).getUTCDate() + "-" + new Date(s).getUTCMonth() + "-" + new Date(s).getUTCFullYear()).includes(new Date(upcomingHoliday.date).getUTCDate() + "-" + new Date(upcomingHoliday.date).getUTCMonth() + "-" + new Date(upcomingHoliday.date).getUTCFullYear());
                        mikvahOpen = colonyData.mikvahOpenAt.map((s) => new Date(s).getUTCDate() + "-" + new Date(s).getUTCMonth() + "-" + new Date(s).getUTCFullYear()).includes(new Date(upcomingHoliday.date).getUTCDate() + "-" + new Date(upcomingHoliday.date).getUTCMonth() + "-" + new Date(upcomingHoliday.date).getUTCFullYear());
                        mussafReservedBy = Object.values(minyanRecord || {})?.[1].find(record => record.reservedMussaf)?.user_name;//needs to be tested before yom tov, and charged then
                        baalKorahInfo = {
                            '1-4': Object.values(minyanRecord || {})?.[1].find(record => record.baalKorah && ['1-4', 'full'].includes(record.preparedFor))?.user_name,
                            '5-end': Object.values(minyanRecord || {})?.[1].find(record => record.baalKorah && ['5-end', 'full'].includes(record.preparedFor))?.user_name,
                        }//needs to be tested before yom tov, and charged then
                        return (
                            <div key={upcomingHoliday.date} className="max-w-7xl mx-auto px-4 py-5 md:py-16 text-left">
                                <div className="grid grid-cols-12 md:gap-11 gap-8">
                                    <div className="md:col-span-8 col-span-12">
                                        <div className="border-dependable border bg-gray-100 min-h-full">
                                            <div className="col-span-4 text-center text-white bg-dependable p-4 md:p-6 text-xl md:text-3xl font-semibold uppercase">
                                                sign up for minyan for {upcomingHoliday.displayName}
                                            </div>
                                            <div
                                                key={upcomingHoliday.name}
                                                className="p-4 lg:p-6 md:pl-16 border-dependable border border-t-0 col-span-4"
                                            >
                                                <p className="mt-3 text-3xl font-semibold">
                                                    {upcomingHoliday.displayName} {upcomingHoliday.emoji || ""}
                                                </p>
                                                <Moment
                                                    className="mb-4 block text-sm"
                                                    format="dddd MMM D, YYYY"
                                                    date={new Date(upcomingHoliday.date)}
                                                />
                                                <MinyanSignup eventData={upcomingHoliday} holiday={true} holidayName={upcomingHoliday.name} />
                                            </div>
                                        </div>
                                    </div>
                                    <MinyanStatus date={upcomingHoliday.date} eventName={upcomingHoliday.displayName} isHaliday={true} sureCount={sureCount} onlyIfCount={onlyIfCount} zmanInfo={{ ...zmanInfo, date: new Date(upcomingHoliday.date) }} hasMikvah={hasMikvah} shulOpen={shulOpen} mikvahOpen={mikvahOpen} />
                                </div>
                            </div>
                        )
                    })}
                </>
            )}
            {LaterSignupOpen && <LaterSignup setLaterSignupOpen={setLaterSignupOpen} />}
        </div>
    );
};

export default MinyanForm;
