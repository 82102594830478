import { useState } from "react";
import { FaArrowAltCircleRight, /* FaCheck */ } from "react-icons/fa";
import { useAuth } from "../../../context/auth";
import Post from "../../../helpers/ajax/post";
import BalanceModal from "../../+balance/balance";

const WinterForm = () => {
    const { user, colonyData } = useAuth();
    const [BypassCode, updateBypassCode] = useState("");
    const [SubmitSuccess, setSubmitSuccess] = useState(false);
    const [Combination, setCombination] = useState("");
    const [SpecialInstructions, setSpecialInstructions] = useState("");
    const [BreakerOn, setBreakerOn] = useState(false);
    const [HeatOn, setHeatOn] = useState(false);
    const [toiletAmount, setToiletAmount] = useState(1);
    const [sinkAmount, setSinkAmount] = useState(1);
    // const [AcceptedAgreement, setAcceptedAgreement] = useState(false);
    const [Error, setError] = useState(null);
    const [Requesting, setRequesting] = useState();
    const waiverLinks = {
        "Greenwood Park Resort Homes Inc.": "CBFCIBAA3AAABLblqZhBqwN2KoSBKlokk0iqov7suuLrKypkCod2ni-TGBuhMjI2d0rbgXKeB3TLvL-8qU04*",
        "Leisure Lake Estates": "CBFCIBAA3AAABLblqZhC9crVOVMIY0Azw2ncjiOxnTm3ylo3mh7-7hWSjC4wa0OWEgOYp-r0tGLcoLTr0ipc*",
        "Rosemond Owners Corp": "CBFCIBAA3AAABLblqZhD6lduFVOeogNq1S8AWMXwH5I1QqwIZnN5COj7Ll1uWsV3qPUPbMbNyAHRvhZ6EaDs*",
        "Lakeview Estates Condominium ": "CBFCIBAA3AAABLblqZhAj-mWi7e6jxc0mmY4YWPgm1lvA-BvjX2HY6ud3KA0tMDI6fSVlMXxCl11CU6bTy68*",
        "Tribeca Sullivan LLC": "CBFCIBAA3AAABLblqZhCP1GLE_sTPmpjHNjyFxr7Tr0c7bFGoTG61YqeJbmw27_DK57qXDqvjxOFkjGLgVO0*",
        "Grove Estates Condmonuim": "CBFCIBAA3AAABLblqZhA_IbR8Zvt9PTE5cD_Tw1lB82KEGlE6_wecXL87FMkmPvtXI5zdwjCjKXnLZITySps*",
        "Parkside Homes": "CBFCIBAA3AAABLblqZhDLYhN2l1KYYBXA1ocZoSi9KnhDHG_MZmmphJPCw_1CRnq1n28wkAJR2-0Dvhfy7zE*",
        "Laurel Ledge Villas ": "CBFCIBAA3AAABLblqZhBMtcdTFIffoQ5bhvP_u6GF79_iVrEZARB-iIkMuwvL2Z-E4IgakkYyxKMmu8gzN4k*",
        "Twin Oaks Village Condominium": "CBFCIBAA3AAABLblqZhA1zcFrYbb2DRFoRJ8z8e4_EP7bry8PeKHx6yRdpjlI67k-pEpKz-r4A6C6WwUdp6A*",
        "West Park Estates": "CBFCIBAA3AAABLblqZhA26b0dvdzgcFDyfls6x9D-D1JNjkH8YPXdvJBv_Z1bfHwXCnJm38grKWWVpQ_bT8w*",
        "Waverly Gardens Condo": "CBFCIBAA3AAABLblqZhDthRErE2gkKtKlXPxBCKhwEPAAVCVktNCoTQzfB_42rODKG2FI2JmIV9XTHk3MW1g*",
        "Laurel Crest Condominium": "CBFCIBAA3AAABLblqZhDYNpg8M3xGnjjkNNPub8qrziGuFA4aDa__x04oO2bF3F-A6NEH4DqEHBQUArWyFeU*",
        "Mesorah Woods": "CBFCIBAA3AAABLblqZhDqqihVc600hwLQjmDY_oRXv20fRli_80yqaCOx6rfOT1YaHdUHH2fOGm4keH9AuP8*",
        "Regency Estates Condominium ": "CBFCIBAA3AAABLblqZhAxw-eE9qfWq66nK35meC9pVVPR1x7EbT1BDGxL8Fqj5EbUeJIQmHCcRD76s1LKug4*",
        "Alpine Acres": "CBFCIBAA3AAABLblqZhAlEo20IqgE9BphP_MUvuBd2R-mvh_tUQ-lpDf_RbpH0LzwA-_oxUhGEsTZm0mwUPc*",
        "Mountain Woods Estates ": "CBFCIBAA3AAABLblqZhAiaLdgf8Ud1Za_o7TFG9kdescbTCvSsJEnaSNKbePYEOsu5mnPhtfcz0xV_4OsGFw*",
        "Mountain Acres Condominium": "CBFCIBAA3AAABLblqZhCmutKez3vgXolfaIwdQ9FFNywev0h4AqjCoEL5qL167gUNCZkYVVfiAmLAfCkr5Ig*",
        "Garden Terrace Estates Condominium ": "CBFCIBAA3AAABLblqZhD2MclM6C_BZQO_N7tDd1s7MWiB1hMn4zMAZ-7RFU3x8EKAHGkjnGF6FimGontH7EA*",
        "Swan Manor Condominiums": "CBFCIBAA3AAABLblqZhAqV3wJxZlFpT3-q1Zk7AEA4eVFHp242BXXPuIy7UiZv9bi7wmAtVPW7R2zP5ok3l4*",
        "Fallsview Estates ": "CBFCIBAA3AAABLblqZhBtqdeM9O-c2xFR9wCWAVS9ZiUSkCvCMbfoewlIr5L5a76_L4cjmVcji117EIBDsvQ*",
        "Birchwood Estates Condominium ": "CBFCIBAA3AAABLblqZhCxrNXiMjuaAZUX-_iR5n280DZ7yLYzUVzeRE1Of4e0KPLHnB6lqQXQas6WmBLHMG0*",
        "Forest Park Estates": "CBFCIBAA3AAABLblqZhDCAAQm1S4_zslYphAZEIiIhFde88RaAhY7HeQt57UMMzxl1HeVi1pHiEuOmPcYHcs*",
        "52/42 Luxury Villas": "CBFCIBAA3AAABLblqZhCMCSakm1ck5r8lz_dqu33GQ4d-8CqilUfsHe85aHD_GoDlj720nBSH46wgEtSC2ss*",
        "Chestnut Court": "CBFCIBAA3AAABLblqZhAosAo-hsEmgkIZVLVSrWkAmz1f2-xYNLIGHBl7qHA9u4UdsjTb71WdqLYSKEa3roU*",
        "Venetian Villas Condominium": "CBFCIBAA3AAABLblqZhASRqnISIOgEwiYNbModT5XVebdAoyZYixElBpAys1pGTPHrReUd15lCOtxuY8I_38*",
        "Aspen Heights Condominium": "CBFCIBAA3AAABLblqZhC-P_RE8AwDSULtC-f9RMFfwXDaxxw0na_tWezsZ1XszZI6zjQld09hqI0n60FGCxA*",
    }

    const submitRequest = async (ev) => {
        ev.preventDefault();
        // setError(null);
        // if (!AcceptedAgreement) {
        //     return setError("Please accept the terms and conditions.");
        // }
        setRequesting(true);
        try {
            window.open(`https://na4.documents.adobe.com/public/esignWidget?wid=${waiverLinks[colonyData.name]}`, 'SignatureWindow', `width=${800},height=${600},left=${(window.screen.width - 800) / 2},top=${2},popup=yes,toolbar=no,menubar=no,location=no,status=no`);
            await Post("/api/user/request", {
                colonyName: colonyData.name,
                toiletAmount: toiletAmount,
                sinkAmount: sinkAmount,
                specialInstructions: SpecialInstructions,
                todo: "Winterize",
                combinationCode: Combination,
                breaker: BreakerOn,
                heat: HeatOn,
                bypassCode: BypassCode
            });
            setSubmitSuccess(true);
        } catch (error) {
            setError(error.error);
        } finally {
            setRequesting(false);
        }
    };

    return (
        <>
            <BalanceModal blockRequest={true} updateBypassCode={updateBypassCode} />
            <form onSubmit={submitRequest}>
                <div className="grid grid-cols-12 md:gap-8 mb-8">
                    <div className="md:col-span-2 md:col-end-4 col-span-12 self-center font-semibold">Full Name</div>
                    <div className="md:col-span-5 col-span-12">
                        <input disabled={true} name="full_name" value={`${user.first_name} ${user.last_name}`} type="text" className="disabled:opacity-60 focus:ring-2 ring-dependable ring-offset-2 w-full h-12 px-4 border focus:outline-none border-dependable-gold" />
                    </div>
                </div>
                <div className="grid grid-cols-12 md:gap-8 mb-8">
                    <div className="md:col-span-2 md:col-end-4 col-span-12 self-center font-semibold">Unit #</div>
                    <div className="md:col-span-5 col-span-12">
                        <input disabled={true} name="unit" value={user.unit} type="text" className="disabled:opacity-60 focus:ring-2 ring-dependable ring-offset-2 w-full h-12 px-4 border focus:outline-none border-dependable-gold" />
                    </div>
                </div>
                <div className="grid grid-cols-12 md:gap-8 mb-8">
                    <div className="md:col-span-2 md:col-end-4 col-span-12 self-center font-semibold">Combination Code</div>
                    <div className="md:col-span-5 col-span-12">
                        <input required disabled={SubmitSuccess} name="combination" onChange={(e) => setCombination(e.target.value)} value={Combination} type="text" placeholder="12345" className="disabled:opacity-60 focus:ring-2 ring-dependable ring-offset-2 w-full h-12 px-4 border focus:outline-none border-dependable-gold" />
                    </div>
                </div>
                <div className="grid grid-cols-12 md:gap-8 mb-8">
                    <div className="md:col-span-2 md:col-end-4 col-span-12 self-center font-semibold">Amount of Toilets</div>
                    <div className="md:col-span-5 col-span-12">
                        <select className="disabled:opacity-60 focus:ring-2 ring-dependable ring-offset-2 w-full h-12 px-4 border focus:outline-none border-dependable-gold" value={toiletAmount}
                            onChange={(e) => setToiletAmount(e.target.value)}>
                            {[...Array(10)].map((_, i) => (
                                <option key={i + 1} value={i + 1}>{i + 1}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="grid grid-cols-12 md:gap-8 mb-8">
                    <div className="md:col-span-2 md:col-end-4 col-span-12 self-center font-semibold">Amount of Sinks</div>
                    <div className="md:col-span-5 col-span-12">
                        <select className="disabled:opacity-60 focus:ring-2 ring-dependable ring-offset-2 w-full h-12 px-4 border focus:outline-none border-dependable-gold" value={sinkAmount}
                            onChange={(e) => setSinkAmount(e.target.value)}>
                            {[...Array(10)].map((_, i) => (
                                <option key={i + 1} value={i + 1}>{i + 1}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="grid grid-cols-12 md:gap-8 mb-8">
                    <div className="md:col-span-2 md:col-end-4 col-span-12 self-start md:pt-2 font-semibold">Special instructions</div>
                    <div className="md:col-span-5 col-span-12">
                        <textarea disabled={SubmitSuccess} name="combination" onChange={(e) => setSpecialInstructions(e.target.value)} value={SpecialInstructions} type="text" placeholder="Special instructions" rows={3} className="disabled:opacity-60 focus:ring-2 ring-dependable ring-offset-2 w-full py-4 px-4 border focus:outline-none border-dependable-gold" />
                    </div>
                </div>
                <div className="grid grid-cols-12 md:gap-8 mb-8">
                    <div className="md:col-span-2 md:col-end-4 col-span-12 self-start md:pt-2 font-semibold">Should we turn off your electric breaker?</div>
                    <div className="md:col-span-5 col-span-12">
                        <div className="inline-block rounded-md overflow-hidden border border-dependable-gold">
                            <button type="button" onClick={() => setBreakerOn(false)} className={`focus:outline-none transition duration-200 py-2 px-10 font-semibold ${!BreakerOn ? "bg-dependable-gold text-white" : ""}`}>
                                Yes
                            </button>
                            <button type="button" onClick={() => setBreakerOn(true)} className={`focus:outline-none transition duration-200 py-2 px-10 font-semibold ${BreakerOn ? "bg-dependable-gold text-white" : ""}`}>
                                No
                            </button>
                        </div>
                        <p className="font-semibold mt-1">If you don't select a preference we will shut off your breaker.</p>
                    </div>
                </div>
                {BreakerOn && (
                    <div className="grid grid-cols-12 md:gap-8 mb-8">
                        <div className="md:col-span-2 md:col-end-4 col-span-12 self-start md:pt-2 font-semibold">Should the heat be left on?</div>
                        <div className="md:col-span-5 col-span-12">
                            <div className="inline-block rounded-md overflow-hidden border border-dependable-gold">
                                <button type="button" onClick={() => setHeatOn(true)} className={`focus:outline-none transition duration-200 py-2 px-10 font-semibold ${HeatOn ? "bg-dependable-gold text-white" : ""}`}>
                                    Yes
                                </button>
                                <button type="button" onClick={() => setHeatOn(false)} className={`focus:outline-none transition duration-200 py-2 px-10 font-semibold ${!HeatOn ? "bg-dependable-gold text-white" : ""}`}>
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                <div className="grid grid-cols-12 md:gap-8 mb-8">
                    <div className="md:col-span-2 md:col-end-4 col-span-12"></div>
                    <div className="md:col-span-8 col-span-12 py-4">
                        {/* <p className="text-xl font-semibold">AGREEMENT FOR WINTERIZATION SERVICES:</p>
                        <p className="">AGREEMENT made by the "Owner":</p>
                        <p className="h-44 overflow-scroll mt-2 mb-6 shadow-inner text-sm p-2">
                            AGREED that, at the request of the unit Owner, the Condo shall perform winterization services for said Owner including, but not limited to, draining the water from the unit's pipes, shutting off the water entering the home, and filling certain pipes and receptacles with compatible chemicals to prevent frost and/or freeze damage to the water system within the Owner's Unit, and it is further AGREED that this Winterization service is being performed as a courtesy for the Owners who do not use their premises over the Winter months, which must be acknowledge in the same manner by Provider and that no unit will be done unless the procedure herein is followed, and it is further
                            <br />
                            <br />
                            AGREED that it is the responsibility of unit owners to indicate to management agency in time when they request winterization, of any changes they made to their unit that needs to be known when winterizing unit, and it is further AGREED that Owner has the right to arrange alternate Winterization service with their choice of provider at Owner's expense, and that Condo as indemnitee is held harmless in the event that damage occurs from Owner's alternate arrangements for Winterization or lack thereof, and it is further AGREED that the Condo may revoke this service at any time provided that the Owner is given due notice of discontinuation of said courtesy service by email or fax or US mail or via a phone, and it is further
                            <br />
                            <br />
                            AGREED that Condo will take every reasonable care to perform said Winterization including but not limited to service with care and to standards reasonably expected to prevent freeze/frost damage, however, should damage occur to Owner's unit during and/or after Winterization from any conditions including but not limited to, weather conditions such as, but not limited to, prolonged or unseasonable cold temperatures, etc. Owners as Indemnitors shall Indemnify and hold harmless Condo, as Indemnitees, its shareholders, officers, agents and/or management company Dependable Management Services, Inc., from all loss, damages, costs and attorney's fees that may result from said weather damages, and it is further AGREED that Owners shall have no claim or cause of action against property management company Dependable Management Services, of P.O. Box 416, Monroe, NY 10949, for any loss, damage or costs arising due to prolonged and/or unseasonably cold weather on the Owners'
                            respective unit, or for that matter any other loss, damage or costs arising as a part of the winterization process, and it is further
                            <br />
                            <br />
                            AGREED that the terms of this service shall terminate with the sale of the Unit. The undersigned hereby agree to the terms until such time as this agreement is revoked by Notice, as described above: The undersigned understands and acknowledges that, by clicking 'Accept' it is consenting and agreeing to the Condominium's engaging in the Winterization service for their unit pursuant to this contract, and the undersigned understands and acknowledges that he/she will be bound by said contract by clicking 'Accept'.
                        </p>
                        <div className="select-none">
                            <label htmlFor={`AcceptedAgreement`}>
                                <input id={`AcceptedAgreement`} className="checked:bg-dependable-gold w-0 h-0" type="checkbox" onChange={(e) => setAcceptedAgreement(e.target.checked)} checked={AcceptedAgreement} />
                                <span className={` w-5 h-5 inline-flex items-center justify-center align-text-bottom mr-2 md:mr-3 border border-dependable-gold ${AcceptedAgreement ? "bg-dependable-gold" : "bg-white"}`}>{!!AcceptedAgreement && <FaCheck className="text-white" />}</span>I agree to the terms and conditions
                            </label>
                        </div> */}
                        <p className="text-lg font-semibold mb-4">
                            Please click <a href={`https://na4.documents.adobe.com/public/esignWidget?wid=${waiverLinks[colonyData.name]}`} target="_blank" rel="noreferrer" className="text-dependable-green hover:underline">here</a> to sign our waiver.
                            <br />We will not be able to proceed without your signature.
                        </p>
                    </div>
                </div>
                <div className="grid grid-cols-12 md:gap-8 mb-8">
                    <div className="md:col-span-2 md:col-end-4 col-span-12 self-center font-semibold"></div>
                    <div className="md:col-span-5 col-span-12">
                        <button disabled={Requesting || SubmitSuccess} className="disabled:opacity-50 border-2 border-dependable-green py-3 px-5 font-semibold inline-block">
                            Submit Request
                            <FaArrowAltCircleRight className="inline-block text-dependable-green ml-3 align-text-top" />
                        </button>
                    </div>
                </div>
                {!!Error && (
                    <div className="grid grid-cols-12 md:gap-8 mb-8">
                        <div className="md:col-span-2 md:col-end-4 col-span-12 self-center font-semibold"></div>
                        <div className="md:col-span-5 col-span-12">
                            <p className="text-red-700">{Error}</p>
                        </div>
                    </div>
                )}
                {SubmitSuccess && (
                    <div className="grid grid-cols-12 md:gap-8 mb-8">
                        <div className="md:col-span-2 md:col-end-4 col-span-12 self-center font-semibold"></div>
                        <div className="md:col-span-8 col-span-12">
                            <p className="text-dependable-green">
                                Thank you for your request. Please allow 2-3 business days for your request to be confirmed.
                                <br />
                                If you do not receive a confirmation email after 3 business days, please contact us at winter@dependablems.com to confirm receipt of your request
                                <br />
                                Requests submitted after Wednesday 3:00pm will be processed the following week
                            </p>
                        </div>
                    </div>
                )}
            </form >
        </>
    );
};

export default WinterForm;
